import { render, staticRenderFns } from "./CompletedRequests.vue?vue&type=template&id=775e9773&scoped=true"
import script from "./CompletedRequests.vue?vue&type=script&lang=js"
export * from "./CompletedRequests.vue?vue&type=script&lang=js"
import style0 from "./CompletedRequests.vue?vue&type=style&index=0&id=775e9773&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775e9773",
  null
  
)

export default component.exports