<template>
	<v-main class="pa-0" :key="componentKey">
		<!-- <car-info-summary :summary="carInfoSummary" v-if="carInfoSummary" @filterStatus="filterStatus" /> -->
		<!-- <v-spacer class="my-5"></v-spacer> -->
		<v-card elevation="1">
			<v-card-title
				class="text-subtitle-1 font-weight-bold"
				:class="$vuetify.theme.dark ? 'secondary darken-1' : 'grey lighten-3 grey--text text--darken-1'"
			>
				<v-icon
					small
					left
					:class="$vuetify.theme.dark ? 'secondary darken-1' : ' grey--text text--darken-1'"
					v-text="'fas fa-tasks'"
				></v-icon>
				Tamamlanan Talepler
				<v-spacer></v-spacer>
				<v-btn class="text-capitalize" small color="primary" @click="exportData" :loading="exporting">
					<v-icon x-small left v-text="'fas fa-file-csv'"></v-icon>
					<span>Csv Olarak İndir</span>
				</v-btn>
			</v-card-title>

			<v-card-text class="pa-0">
				<v-data-table
					:headers="headers"
					:items="carInfoGridData"
					:options.sync="options"
					:server-items-length="carInfoTotalCount"
					class="elevation-0"
					@click:row="approveSupervisor"
				>
					<template v-slot:top>
						<v-dialog v-model="supervisorDialog" max-width="500">
							<v-card>
								<v-card-title class="text-h5"> Yetki Atansın mı ? </v-card-title>
								<v-card-text> Süreç Yetkilisi Olarak Atanacaksınız ! </v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="primary darken-1" text @click="discardSupervisor()">
										Hayır, Sadece inceleyeceğim
									</v-btn>
									<v-btn color="teal" text @click="setSupervisor(selectedItem)"> Evet, Yetkilendir! </v-btn>
								</v-card-actions>
							</v-card>
						</v-dialog>
						<car-info-filter ref="filterRef" :gridPreset="gridPreset" v-if="gridPreset" @filterBind="filterBind" />
					</template>

					<template v-slot:[`item.brandName`]="{ item }">
						<img
							:src="getCarImage(item.brandName)"
							:alt="item.brandName"
							@error="imgErrorEvent"
							contain
							width="40px"
							height="40px"
						/>
					</template>

					<template v-slot:[`item.year`]="{ item }">
						{{ item.year }} {{ item.brandName }} {{ item.modelName }} <br />
						{{ item.bodyTypeName }} {{ item.fuelTypeName }} {{ item.transmissionTypeName }} <br />
						{{ item.version }}
					</template>

					<template v-slot:[`item.date`]="{ item }">
						{{ $moment.utc(item.date).local().format('DD-MM-YYYY HH:mm') }}
					</template>

					<template v-slot:[`item.paintedCount`]="{ item }">
						<v-chip x-small class="mx-1" color="green white--text" :class="{ 'hide-chip': item.scratchedCount === 0 }">
							{{ item.scratchedCount }}Ç
						</v-chip>
						<v-chip x-small class="mx-1" color="primary white--text" :class="{ 'hide-chip': item.paintedCount === 0 }"
							>{{ item.paintedCount }}B
						</v-chip>
						<v-chip x-small class="mx-1" color="red white--text" :class="{ 'hide-chip': item.replacedCount === 0 }">
							{{ item.replacedCount }}D
						</v-chip>
					</template>

					<template v-slot:[`item.galleryPrice`]="{ item }">
						{{ item.galleryPrice | tl({ avoidEmptyDecimals: '' }) }}
					</template>
					<template v-slot:[`item.retailPrice`]="{ item }">
						{{ item.retailPrice | tl({ avoidEmptyDecimals: '' }) }}
					</template>
					<template v-slot:[`item.actions`]="{ item }">
						<v-btn icon small @click.stop="openRequest(item)">
							<v-icon color="teal" x-small v-text="'fas fa-external-link-alt'"></v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card-text>
		</v-card>
	</v-main>
</template>

<script>
import EventBus from '@/event-bus';
import { mapActions, mapGetters } from 'vuex';
import { saveByteArray } from '@/common/utils';
import {
	FETCH_GRID_DATA,
	EXPORT_GRID_DATA,
	// FETCH_SUMMARY,
	SET_SUPERVISOR,
	FETCH_GRID_FILTER_PRESET,
} from '@/store/modules/carInfo.module';
// import CarInfoSummary from '@/components/backoffice/CarInfo/Summary.vue';
import CarInfoFilter from '@/components/backoffice/CarInfo/Filter.vue';

export default {
	name: 'CompletedRequests',
	components: {
		// CarInfoSummary,
		CarInfoFilter,
	},
	data() {
		return {
			componentKey: 0,
			exporting: false,
			supervisorDialog: false,
			selectedItem: null,
			options: {
				itemsPerPage: 10,
				page: 1,
				sortBy: [],
				sortDesc: [true],
				mustSort: true,
			},
			headers: [
				{ text: 'Talep Tarihi', value: 'date', align: 'center' },
				{ text: 'Talep No', value: 'trackingNumber' },
				{ text: 'Marka', value: 'brandName' },
				{ text: 'Araç Bilgisi', value: 'year', align: 'left', sortable: false },
				{ text: 'Hasar', value: 'paintedCount', align: 'center', sortable: false, width: '175px' },
				{ text: 'Kilometre', value: 'kilometer', align: 'center' },
				{ text: 'Takas', value: 'isExchangeRequested', align: 'center' },
				{ text: 'Müşteri', value: 'customerName' },
				{ text: 'Yetkili', value: 'supervisorName' },
				{ text: 'Teklif Tutarı', value: 'galleryPrice' },
				{ text: 'SmartIQ Fiyat', value: 'retailPrice' },
				{ text: 'İşlemler', value: 'actions', align: 'center', sortable: false },
			],
		};
	},
	computed: {
		...mapGetters(['carInfoGridData', 'carInfoTotalCount', 'carInfoSummary', 'gridPreset']),
	},
	created() {
		this.$nextTick(() => {
			this.fetchGridFilterPreset();
			// this.fetchSummary();
			EventBus.$on('refreshRequestGrid', () => {
				this.componentKey += 1;
			});
		});
	},
	beforeDestroy() {
		EventBus.$off('refreshRequestGrid');
	},
	methods: {
		...mapActions({
			fetchGridData: FETCH_GRID_DATA,
			exportGridData: EXPORT_GRID_DATA,
			fetchGridFilterPreset: FETCH_GRID_FILTER_PRESET,
			// fetchSummary: FETCH_SUMMARY,
		}),
		exportData() {
			this.exporting = true;
			this.exportGridData({
				endpoint: 'car/getcompletedgridexportdata',
				payload: {
					...this.$refs.filterRef?.filter,
				},
			})
				.then((response) => {
					saveByteArray(response.fileName, 'csv', response.fileData);
				})
				.finally(() => {
					this.exporting = false;
				});
		},
		openRequest(item) {
			this.selectedItem = item;
			const route = this.$router.resolve({ name: 'carInfoDetails', params: { id: this.selectedItem.carId } });
			window.open(route.href, '_blank');
		},
		imgErrorEvent(e) {
			e.target.src = 'https://via.placeholder.com/40';
		},
		getCarImage(brandName) {
			if (brandName === null) return '';
			return `https://mars-contents.fra1.digitaloceanspaces.com/qa/brand_logo/${brandName.replace(/\s+/g, '-')}.png`;
		},
		discardSupervisor() {
			this.supervisorDialog = false;
			this.gotoDetailPage();
		},
		setSupervisor() {
			this.$store.dispatch(SET_SUPERVISOR, this.selectedItem.carId).then(() => {
				this.supervisorDialog = false;
				this.gotoDetailPage();
			});
		},
		approveSupervisor(item) {
			this.selectedItem = item;
			if (!item.supervisorId) this.supervisorDialog = true;
			else this.gotoDetailPage();
		},
		gotoDetailPage() {
			this.$router.push({ name: 'carInfoDetails', params: { id: this.selectedItem.carId } });
		},
		filterBind(payload) {
			const newPayload = { ...payload, pageSize: this.options.itemsPerPage, pageNumber: 1 };
			this.fetchGridData({ endpoint: 'car/getcompletedgriddata', payload: newPayload });
		},
		// eslint-disable-next-line no-unused-vars
		// filterStatus(status, onlyThisMonth) {
		// 	if (!this.$refs.filterRef || !status) return;

		// 	this.options = {
		// 		itemsPerPage: 10,
		// 		page: 1,
		// 		sortBy: [],
		// 		sortDesc: [true],
		// 		mustSort: true,
		// 	};

		// 	let startDate = null;
		// 	let endDate = null;

		// 	if (onlyThisMonth) {
		// 		const days = parseInt(this.$moment().format('DD'), 10);

		// 		startDate = this.$moment()
		// 			.subtract(days - 1, 'days')
		// 			.format('YYYY-MM-DD');
		// 		endDate = this.$moment()
		// 			.add(1, 'months')
		// 			.subtract(days, 'days')
		// 			.format('YYYY-MM-DD');
		// 	}

		// 	const payload = { ...this.$refs.filterRef?.filter, statusId: status.id, startDate, endDate };
		// 	const newPayload = { ...payload, pageSize: this.options.itemsPerPage, pageNumber: 1 };
		// 	this.$refs.filterRef.filter.statusId = status.id;
		// 	this.$refs.filterRef.filter.startDate = startDate;
		// 	this.$refs.filterRef.filter.endDate = endDate;
		// 	this.fetchGridData({ endpoint: 'car/getcompletedgriddata', payload: newPayload });
		// },
	},
	watch: {
		options: {
			handler(value) {
				const { sortBy, sortDesc } = value;
				const sortField = sortBy[0] ?? 0;
				const fieldIndex = this.headers.findIndex((x) => x.value === sortField);
				const orderByFieldId = fieldIndex < 0 ? 0 : fieldIndex + 1;
				const orderByDesc = sortDesc[0] ?? true;

				const payload = { ...this.$refs.filterRef?.filter };

				const newPayload = {
					...payload,
					pageSize: value.itemsPerPage,
					pageNumber: value.page,
					statusId: 0,
					orderByFieldId,
					orderByDesc,
				};
				this.fetchGridData({ endpoint: 'car/getcompletedgriddata', payload: newPayload });
			},
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep {
	.theme--light .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.03);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.03);
						}
					}
				}
			}
		}
	}

	.theme--dark .v-data-table {
		tbody {
			tr {
				&:nth-of-type(even) {
					background-color: rgba(0, 0, 0, 0.3);

					td {
						&.v-data-table__mobile-row {
							background-color: rgba(0, 0, 0, 0.3);
						}
					}
				}
			}
		}
	}
}
.hide-chip {
	visibility: hidden;
}
</style>
